/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import App from "./App.vue";
import { differenceInYears } from "date-fns";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
import lodashHelper from './helper/lodashUtility';
import moment from "moment";
// axios
import axios from "./axios.js";
// API Calls
import "./http/requests";

// Theme Configurations
import "../themeConfig.js";
// Globally Registered Components
import "./globalComponents.js";


// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// VeeValidate
import VeeValidate from "vee-validate";

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

import OtpInput from "@bachdgvn/vue-otp-input";

Vue.component("v-otp-input", OtpInput);
// Chart
// import Donut from "vue-css-donut-chart";
// import "vue-css-donut-chart/dist/vcdonut.css";
import VueApexCharts from "vue-apexcharts";
Vue.use(Vuesax);

Vue.prototype.$http = axios;


Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);


VeeValidate.setMode("eager");

// validator checks if age is 18 years

VeeValidate.Validator.extend("18-years-age", {
  getMessage: _field => "Must be over 18 to proceed",
  validate: value => {
    if (differenceInYears(new Date(), new Date(value)) >= 18) {
      return true;
    }
    return false;
  }
});

// custom directive to make button rounded

Vue.directive("round", {
  bind(el, binding, vnode) {
    el.style.borderRadius = "30px";
  }
});

VeeValidate.Validator.localize("en", {
  custom: {
    password: {
      regex:
        "Password must contain at least 8 characters including one uppercase letter,one lowercase letter and one number."
    }
  }
});

// formats currency as $123,56.67
Vue.mixin({
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(value).replace(/([\d,.]+)$/, ' $1') ;
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    replaceLegalContent(str, mix) {
      const RGX = /{{(.*?)}}/g;
      return str.replace(RGX, (x, key, y) => {
        x = 0;
        y = mix;
        key = key.trim().split(".");
        while (y && x < key.length) {
          y = y[key[x++]];
        }
        return y != null ? y : "";
      });
    },

    showToastMessage(title = "Success", message = "Success", type = "success") {
      let toastDetail = {};
      const icon = {
        iconClass: "",
        iconChildren: "",
        iconTag: "span"
      };
      toastDetail.timeout = 5000;
      toastDetail.position = "bottom-left";
      toastDetail.closeOnClick = false;
      toastDetail.pauseOnFocusLoss = false;
      toastDetail.pauseOnHover = false;
      toastDetail.draggable = false;
      toastDetail.draggablePercent = 0.1;
      toastDetail.showCloseButtonOnHover = false;
      toastDetail.hideProgressBar = true;
      toastDetail.closeButton = "button";
      toastDetail.icon = icon;
      toastDetail.rtl = false;
      toastDetail.message = message;

      if (type == "success") {
        toastDetail.icon.iconClass = "material-icons text-success";
        toastDetail.icon.iconChildren = "check_circle_outline";
        this.$toast.success(<div class="container"><h3>{title}</h3><p class="empty:hidden">{message}</p></div>, toastDetail);
      } else {
        toastDetail.timeout = false,
        toastDetail.icon.iconClass = "material-icons text-danger";
        toastDetail.icon.iconChildren = "highlight_off";
        this.$toast.error(<div class="container"><h3>{title}</h3><p class="empty:hidden">{message}</p></div>, toastDetail);
      }
    },

    handleDateChange(event) {
      const value = event.target.value.split("/");
      const dayValue = value[0].charAt(0);
      const monthValue = value[1] ? value[1].charAt(0) : '';
      if (value[0].length == 1) {
        if (dayValue >= 4) {
          event.target.value = "0" + dayValue;
        }
        if (event.key == '/') {
          event.target.value = "0" + value[0] + "/";
        }
      }

      if (value[1].length == 1) {
        if (monthValue && monthValue >= 2) {
          event.target.value = value[0] + "/0" + monthValue;
        }
        if (event.key == '/') {
          event.target.value = value[0] + "/0" + monthValue + "/";
        }
      }
    },

    handleDatePickerFormat(value) {
      let showDatePickerValid = false;
      showDatePickerValid = moment(value, 'DD/MM/YYYY', true).isValid();

      if (!showDatePickerValid) {
        return false;
      }

      const year = moment(value, "DD/MM/YYYY").year();
      showDatePickerValid = (parseInt(year) >= 1800 && parseInt(year) <= 2999);
      return showDatePickerValid;
    },
  }
});

Vue.use(VeeValidate, {
  classes: true
});

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 30,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.message === toast.message
    ).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  }
});

const lodashHelperPlugin = {
  install () {
    Vue.lodashHelper = lodashHelper
    Vue.prototype.$lodashHelper = lodashHelper
  }
}

Vue.use(lodashHelperPlugin)


// Vue.use(Donut);
// Feather font icon
require("./assets/css/iconfont.css");

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
